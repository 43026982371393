/*
===============
Animations
===============
*/
@-webkit-keyframes growSideways {
  from {
    -webkit-transform: scaleX(0);
            transform: scaleX(0);
  }
  to {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
  }
}
@keyframes growSideways {
  from {
    -webkit-transform: scaleX(0);
            transform: scaleX(0);
  }
  to {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
  }
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-webkit-keyframes appLogoSpin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes appLogoSpin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

:root {
  --primary-clr: #222;
  --accent-clr: #ff9382;
  --contrast-clr: #222;
  --neutral-clr: #fff;
  --btn-primary-clr: #0080ff;
  --btn-accent-clr: #ffc761;
  --grad-1: #0080ff;
  --grad-2: #d271ea;
  --grad-3: #ff70b7;
  --grad-4: #ff9382;
  --grad-5: #ffc761;
  --grad-6: #f9f871;
  --burger-primary-clr: #fff;
  --burger-bg-clr: rgba(0, 0, 0, 0.8);
  --mascot-size: 5rem;
  --logo-size: 5rem;
  --transition-duration: 250ms;
  /*
	 * Home Page
	 */
  --home-s1-bg: #fff;
  --home-s2-bg: #f5f7f9;
  --home-s3-bg: #fff;
  --card-front-clr: #ffc761;
  --card-back-clr: #222;
  --signature-font-size: 4rem;
  --perspective-distance: 10rem;
  --card-signature-distance: 9rem;
  --card-subtitle-distance: 6rem;
  --card-border-distance: 3rem;
  --card-border-spacer: 1em;
  --card-body-distance: 6rem;
  /*
	 * Home Page End
	 */
  /*
	 * Education Page
	 */
  /*
	 * Education Page End
	 */
}

.page__home {
  overflow-y: scroll;
  scrollbar-width: thin;
  scrollbar-color: var(--primary-clr) transparent;
  scroll-behavior: smooth;
  -ms-scroll-snap-type: y mandatory;
      scroll-snap-type: y mandatory;
  width: 100%;
  height: 100%;
}

.page__home section {
  min-height: 100vh;
  width: 100vw;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  scroll-snap-align: center;
}

.page__home .section-heading {
  margin: 2em auto 0;
  text-align: center;
}

.page__home .intro-section {
  background-color: var(--home-s1-bg);
}

.page__home .project-preview-section {
  background-color: var(--home-s2-bg);
}

.page__home .more-section {
  background-color: var(--home-s3-bg);
}

@media screen and (max-width: 650px) {
  .page__home {
    -ms-scroll-snap-type: none;
        scroll-snap-type: none;
  }
}

/*
===============
Typography
===============
*/
.intro-card__signature {
  font-size: var(--signature-font-size);
  margin-bottom: 0.5em;
}

/*
===============
Layout
===============
*/
@media screen and (min-width: 650px) {
  .intro-section {
    width: clamp(30ch, 90%, 130ch);
    max-width: 100%;
    margin: 3em auto;
    min-height: 80vh;
    background-image: url("../../images/Design/1 Intro/Assets/SVG/introResource_img.svg");
    background-repeat: no-repeat;
    background-position: 130% -30%;
    display: -ms-grid;
    display: grid;
    place-items: center;
  }
  .intro-card__content {
    text-align: center;
    position: relative;
    -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
    -webkit-transition: -webkit-transform 3s ease-in-out;
    transition: -webkit-transform 3s ease-in-out;
    transition: transform 3s ease-in-out;
    transition: transform 3s ease-in-out, -webkit-transform 3s ease-in-out;
    padding: 5em;
    min-height: 25em;
  }
  .intro-card__front,
  .intro-card__back {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
    display: -ms-grid;
    display: grid;
    -ms-flex-line-pack: center;
        align-content: center;
  }
  .intro-card__front::before,
  .intro-card__back::before {
    content: '';
    position: absolute;
    top: var(--card-border-spacer);
    bottom: var(--card-border-spacer);
    left: var(--card-border-spacer);
    right: var(--card-border-spacer);
    border: 3px solid currentColor;
    -webkit-transform: translateZ(var(--card-border-distance));
            transform: translateZ(var(--card-border-distance));
    -moz-transform: translateZ(var(--card-border-distance));
  }
  .intro-card__front {
    padding: 2em 1em;
    background: var(--card-front-clr);
  }
  .intro-card__front .intro-card__signature {
    -webkit-transform: translateZ(var(--card-signature-distance));
            transform: translateZ(var(--card-signature-distance));
    -moz-transform: translateZ(var(--card-signature-distance));
  }
  .intro-card__front .intro-card__subtitle {
    -webkit-transform: translateZ(var(--card-subtitle-distance));
            transform: translateZ(var(--card-subtitle-distance));
    -moz-transform: translateZ(var(--card-subtitle-distance));
  }
  .intro-card__back {
    padding: 5em;
    background: var(--card-back-clr);
    color: var(--card-front-clr);
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
    -moz-transform: rotateY(180deg);
  }
  .intro-card__back .intro-card__body {
    -webkit-transform: translateZ(var(--card-body-distance));
            transform: translateZ(var(--card-body-distance));
    -moz-transform: translateZ(var(--card-body-distance));
    margin: 1em;
  }
  .intro-card {
    padding: 2.5em 4em;
    min-height: 80%;
    width: calc(max(70%, 600px));
    max-width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .intro-card p {
    margin: 1em auto;
    line-height: 1.5em;
  }
  .intro-card:hover .intro-card__content {
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
    -moz-transform: rotateY(180deg);
  }
}

@media screen and (max-width: 650px) {
  .intro-card {
    padding: 1em;
    text-align: center;
  }
  .intro-card .intro-card__front {
    margin: 0 auto 2.5em;
    border-top: 2px solid var(--contrast-clr);
    border-bottom: 2px solid var(--contrast-clr);
    -webkit-animation: fadeIn 3s ease forwards;
            animation: fadeIn 3s ease forwards;
  }
  .intro-card .intro-card__back {
    opacity: 0;
    -webkit-animation: fadeIn 3s 1.5s forwards;
            animation: fadeIn 3s 1.5s forwards;
  }
  .intro-card .intro-card__signature {
    margin: 1.5rem auto;
  }
  .intro-card p {
    margin: 1em auto;
    line-height: 1.5em;
  }
}

.project-preview-section {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  background-image: url("../../images/Decor/TopBlobMan.svg"), url("../../images/Decor/LookUpLeftBlobMan.svg");
  background-repeat: no-repeat, no-repeat;
  background-size: 10%, 15%;
  background-position: 50px 50px, calc(100% - 1em) calc(100% - 7em);
}

.project-preview-section ::-webkit-scrollbar {
  height: 5px;
}

.project-preview-section .project-cards {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  overflow-x: scroll;
  scrollbar-width: thin;
  width: clamp(300px, 100%, 80%);
  margin: clamp(1em, 3em, 5%) 0;
  padding: 2em 0;
}

.project-preview-section .project-card {
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
  -webkit-box-flex: 0;
      -ms-flex: none;
          flex: none;
  overflow: hidden;
  padding-bottom: 1em;
  border-radius: 2em;
  -webkit-box-shadow: 0 0 5px -2px;
          box-shadow: 0 0 5px -2px;
  -webkit-transition: -webkit-transform 500ms ease;
  transition: -webkit-transform 500ms ease;
  transition: transform 500ms ease;
  transition: transform 500ms ease, -webkit-transform 500ms ease;
}

.project-preview-section .project-card:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.project-preview-section .project-card + .project-card {
  margin-left: 1em;
}

.project-preview-section .project-card .project-card-title {
  margin: 1em 0;
  text-align: center;
}

.project-preview-section .project-card img {
  width: 250px;
}

.project-preview-section .project-card p {
  width: 250px;
  margin: 0;
  text-align: center;
  padding: 0 1em 0;
  line-height: 1.3em;
}

.project-preview-section .project-card a {
  text-decoration: none;
  color: inherit;
}

@media screen and (max-width: 970px) {
  .project-preview-section {
    padding-top: 10vh;
  }
  .project-preview-section .project-card:first-child {
    margin-left: 600px;
  }
  .project-preview-section .project-card:last-child {
    margin-right: 50px;
  }
}

.more-section {
  padding-top: 5em;
  padding-bottom: 10em;
}

.more-section .circle-img-container {
  margin: 2em;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  overflow: hidden;
}

.more-section .circle-img-container img {
  width: 110%;
}

.more-section p {
  text-align: center;
  margin: 0 auto 1em;
  padding: 0 1em;
}

.more-section button {
  margin: 0.5em 1em;
}

@media screen and (min-width: 800px) {
  .more-section p,
  .more-section button {
    font-size: 2rem;
  }
}
/*# sourceMappingURL=Home.css.map */